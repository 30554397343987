 // File: src/modules/quan_ly_tai_chinh/constants/loai_chi_tieu.js
// Link tham khảo: https://reactjs.org/docs/hooks-reference.html
// Nhánh: main

export const LOAI_CHI_TIEU = {
    THUONG_XUYEN: 'THUONG_XUYEN',
    MUA_SAM: 'MUA_SAM',
    TRANG_THIET_BI: 'TRANG_THIET_BI', 
    VAN_PHONG_PHAM: 'VAN_PHONG_PHAM',
    AN_UONG: 'AN_UONG',
    DI_LAI: 'DI_LAI',
    KHAC: 'KHAC'
  };
  
  export const TEN_LOAI_CHI_TIEU = {
    [LOAI_CHI_TIEU.THUONG_XUYEN]: 'Chi thường xuyên',
    [LOAI_CHI_TIEU.MUA_SAM]: 'Mua sắm vật chất',
    [LOAI_CHI_TIEU.TRANG_THIET_BI]: 'Trang thiết bị',
    [LOAI_CHI_TIEU.VAN_PHONG_PHAM]: 'Văn phòng phẩm',
    [LOAI_CHI_TIEU.AN_UONG]: 'Ăn uống',
    [LOAI_CHI_TIEU.DI_LAI]: 'Đi lại',
    [LOAI_CHI_TIEU.KHAC]: 'Chi khác'
  };
  
