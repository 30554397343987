 
// File: src/modules/quan_ly_tai_chinh/constants/trang_thai.js
// Nhánh: main

export const TRANG_THAI = {
    DA_THU: 'DA_THU',
    CHUA_THU: 'CHUA_THU',
    DA_CHI: 'DA_CHI',
    CHUA_CHI: 'CHUA_CHI',
    HUY: 'HUY'
  };
  
  export const TEN_TRANG_THAI = {
    [TRANG_THAI.DA_THU]: 'Đã thu',
    [TRANG_THAI.CHUA_THU]: 'Chưa thu',
    [TRANG_THAI.DA_CHI]: 'Đã chi',
    [TRANG_THAI.CHUA_CHI]: 'Chưa chi',
    [TRANG_THAI.HUY]: 'Hủy'
  };