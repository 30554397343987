// src/modules/quan_ly_chi_tiet/constants/loai_kiem_thu.js
export const LOAI_KIEM_THU = {
    UNIT_TEST: 'Unit Test',
    INTEGRATION_TEST: 'Integration Test',
    UI_TEST: 'UI Test',
    PERFORMANCE_TEST: 'Performance Test',
    SECURITY_TEST: 'Security Test',
    UAT: 'User Acceptance Test'
  };
  
  